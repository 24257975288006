<template>
  <div class="import-questions">
    <div class="tab">
      <div class="back" @click="goBack()">
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </div>
      <div class="active"><i class="iconfont icon-kaoshi"></i> 导入试题</div>
      <div class="reImport" v-if="!editFlag">
        <el-button type="primary" @click="importfile()">批量导入</el-button>
      </div>
    </div>

    <div class="question-wrap">
      <div class="item">
        <span class="title">题型</span>
        <div class="question-type">
          <span v-for="(item, index) in type" :key="index" :class="{ 'active': index == typeActive }"
            @click="changeType(index)">{{ item }}</span>
        </div>
      </div>
      <div class="item item1">
        <span class="title">题干</span>
        <div>
          <el-input v-model="context" type="textarea" :rows="5" style="width: 100%"></el-input>
        </div>
      </div>
      <div class="item item2">
        <span class="title">选项个数</span>
        <div v-if="typeActive == 2">
          <span class="active">2</span>
        </div>
        <div v-else>
          <span v-for="(item, index) in optionsNum" :key="index" :class="{ 'active': index == optionsActive }"
            @click="changeOptions(index)">{{ item
            }}</span>
        </div>
      </div>
      <div class="item item3">
        <span class="title">答案</span>
        <div class="answer" v-if="typeActive == 0">
          <el-radio-group v-model="answer" style="padding-top: 10px;">
            <p v-for="(item, index) in answerOPtions" :key="index" style="margin-bottom: 10px;">
              <el-radio :label="item.optionChoise">
                {{ item.optionChoise }}
                <el-input v-model="item.optionMessage" placeholder="请输入内容" style="width: 500px;"></el-input>
              </el-radio>
            </p>

          </el-radio-group>

        </div>
        <div class="answer" v-if="typeActive == 1">
          <el-checkbox-group v-model="answerList">
            <p v-for="(item, index) in answerOPtions" :key="index" style="padding-top: 10px;">
              <el-checkbox :label="item.optionChoise">
                {{ item.optionChoise }}
                <el-input v-model="item.optionMessage" placeholder="请输入内容" style="width: 500px;"></el-input>
              </el-checkbox>
            </p>
          </el-checkbox-group>
        </div>
        <div class="answer" v-if="typeActive == 2">
          <el-radio-group v-model="judgeAnswer">
            <p style="margin-bottom: 10px;">
              <el-radio :label="0">
                错误
              </el-radio>
            </p>
            <p>
              <el-radio :label="1">
                正确
              </el-radio>
            </p>
          </el-radio-group>

        </div>
      </div>
      <div class="item">
        <span class="title">知识点</span>
        <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
        <el-cascader ref="cascader" :props="defaultProps" v-model="value" :options="options" @change="handleChange"
          style="width: 100%;"></el-cascader>

      </div>
      <div class="item">
        <span class="title">解析</span>
        <el-input v-model="analysis" placeholder="请输入内容"></el-input>

      </div>
      <div class="item">
        <span class="title">难易度</span>
        <div>
          <el-radio-group v-model="diffictlyActive">
            <el-radio :label="item.value" v-for="(item, index) in diffictly" :key="index">{{ item.name }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <span class="title">一致性</span>
        <div>
          <el-radio-group v-model="consistencyActive">
            <el-radio :label="item.value" v-for="(item, index) in consistency" :key="index">{{ item.name }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <span class="title">等级</span>
        <div>
          <el-radio-group v-model="levelActive">
            <el-radio :label="item" v-for="(item, index) in level" :key="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <span class="title">图片</span>
        <div>
          <el-image>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
      <div class="item">
        <el-button type="primary" @click="addNewQuestion()">{{ editFlag ? '更新' : '提交' }}</el-button>
      </div>
    </div>


    <el-dialog title="批量导入试题" :visible.sync="centerDialogVisible" width="30%" center @close="dialogClose" :close-on-click-modal="false">
      <div class="import-student-dialog">
        <div class="content">
          <p class="step"><span>Step1</span></p>
          <p class="text">请先下载批量添加试题的模板，若之前已下载过模板请直接进行Step2操作</p>
          <p class="operate">
            <el-button type="primary" plain @click="downloadTemplate()">下载模版</el-button>
          </p>
        </div>
        <div class="content">
          <p class="step"><span>Step2</span></p>
          <p class="text">
            将下载好的模板内添加需要导入的试题信息，保存好后，选择试题的Excel表格
          </p>
          <p class="operate">
            <el-input v-model="fileName" :disabled="true" style="width: 70%"> </el-input>
            <span class="btn">
              <input type="file" name="" ref="file" class="file" accept=".xlsx,.xls" @change="handleFileChange" />
              <el-button size="medium" type="primary" plain>选择Excel</el-button>
            </span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="uploadFile()">立即导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import ApisConfig from '@/apis/api.config';
export default {
  name: 'ImportQuestions',
  data() {
    return {
      value: [],
      centerDialogVisible: false,
      fileName: '',
      selectedFile: null,
      type: ['单选题', '多选题', '判断题'],
      optionsNum: ['1', '2', '3', '4', '5'],
      // optionsNum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      typeActive: 0,
      optionsActive: 3,
      diffictly: [{ name: '容易', value: '1' }, { name: '较易', value: '2' }, { name: '一般', value: '3' }, { name: '较难', value: '4' }, { name: '困难', value: '5' }],
      consistency: [{ name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }],
      level: ['1', '2', '3'],
      question: '',
      diffictlyActive: '1',
      consistencyActive: '1',
      levelActive: '1',
      radio: 'A',
      input: '',
      options: [],
      defaultProps: {
        value: 'path',
        label: 'name',
        expandTrigger: 'hover'
      },
      currentKnowData: null,
      context: '',
      answerOPtions: [
        { optionChoise: "A", optionMessage: "" },
        { optionChoise: "B", optionMessage: "" },
        { optionChoise: "C", optionMessage: "" },
        { optionChoise: "D", optionMessage: "" },
      ],
      answer: 'A',
      id: null,
      editFlag: false,
      toEnglish: ['A', 'B', 'C', 'D', 'E'],
      answerList: [],
      analysis: "",
      judgeAnswer: 0
    };
  },
  components: {},
  created() {
    let id = this.$route.params.id;
    if (id != -1) {
      this.id = id;
      this.editFlag = true;
      this.getQuestion();//回显
    }
    this.getKnowledgeList();

  },
  methods: {
    async getQuestion() {
      const res = await this.Api.databaseManage.getQuestionById({ id: this.id });
      console.log("🚀 ~ getQuestion ~ res:", res)

      let { data } = res
      this.showData(data)

    },
    dialogClose() {
      this.selectedFile = null;
      this.fileName = null;
      this.$refs.file.value = ''
    },
    showData(data) {
      this.analysis = data.analysis;
      this.context = data.context;
      this.typeActive = Number(data.type) - 1;
      this.answerOPtions = JSON.parse(data.options);
      this.optionsActive = this.answerOPtions.length - 1;
      this.diffictlyActive = data.difficulty + '';
      this.consistencyActive = data.consistency + '';
      this.levelActive = data.level + ''
      let parts = data.knowledgePath.split('/');
      let result = parts.map((value, index) => {
        return parts.slice(0, index + 1).join('/');
      });
      this.value = result;
      if (data.type == 1) this.answer = data.answer;
      if (data.type == 2) this.answerList = data.answer.split('');
      if (data.type == 3) this.judgeAnswer = data.answer == 'T' ? 1 : 0;
    },
    handleChange(value) {
      console.log("🚀 ~ handleChange ~ this.value:", this.value)

      this.currentKnowData = this.$refs.cascader.getCheckedNodes()
    },
    changeType(index) {
      if (this.typeActive == index) return
      this.typeActive = index;
    },
    async addNewQuestion() {
      if (!this.context || this.context == '') {
        this.$message.error('题干不能为空');
        return;
      }
      let index = this.answerOPtions.findIndex((item, index) => {
        return !item.optionMessage || item.optionMessage == ''
      });

      if (this.typeActive != 2 && index != -1) {
        this.$message.error('答案选项内容不能为空');
        return;
      }
      if (this.editFlag) {
        this.updateQuestion();
        return;
      }


      const response = await this.Api.databaseManage.addQuestion(this.getData());
      console.log("🚀 ~ addNewQuestion ~ response:", response)
      if (response.code != 200) {
        this.$message.error(response.message);
        return;
      }
      this.$message.success('提交成功');
      this.$router.push('/admin/import-records');

    },

    async getKnowledgeList() {
      // 获取知识点树
      const result = await this.Api.databaseManage.getKnowledgeTree({});
      this.removeEmptyChildren(result.data);
      this.options = result.data;
    },
    async updateQuestion() {

      const response = await this.Api.databaseManage.updateQuestion(this.getData());
      if (response.code != 200) {
        this.$message.error(response.message);
        return;
      }
      this.$message.success('更新成功');
      this.$router.push('/admin/import-records');

    },
    getData() {
      let path = this.value[this.value.length - 1];
      let options, answer;
      if (this.typeActive == 0) {
        answer = this.answer
      }
      if (this.typeActive == 1) {
        answer = this.answerList.join('')
      }

      if (this.typeActive == 2) {
        answer = this.judgeAnswer == 0 ? 'F' : 'T'
      }
      if (this.typeActive == 2) {
        options = "[]"
      } else {
        options = JSON.stringify(this.answerOPtions)
      }
      let data = {
        id: this.editFlag ? this.id : null,
        "type": this.typeActive + 1,
        "knowledgePath": path,
        "difficulty": this.diffictlyActive,
        "consistency": this.consistencyActive,
        "level": this.levelActive,
        "context": this.context,
        "options": options,
        "analysis": this.analysis,
        "answer": answer
      }

      return data
    },
    removeEmptyChildren(data) {
      for (let i = 0; i < data.length; i++) {
        let node = data[i];
        if (node.children && node.children.length === 0) {
          delete node.children;
        } else if (node.children && node.children.length > 0) {
          this.removeEmptyChildren(node.children);
        }
      }
    },

    downloadTemplate() {
      window.location.href = 'http://192.168.6.155/tm/试题模板.xlsx';
      // const res = this.Api.exammanage.getStudentTemplate({});
      // this.downloadFile(res.data);
    },

    downloadFile(file) {
      const blob = new Blob([file]);
      const fileName = '导入模版.xlsx';
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      console.log('🚀 ~ handleFileChange ~ this.selectedFile:', this.selectedFile);
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      axios
        .post(ApisConfig.examApi + '/exam/web/tk/question/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.code != 200) {
            this.$message.error(response.message);
            return;
          }
          this.$message.success('导入成功');

          this.selectedFile = null;
          this.fileName = null;
          this.centerDialogVisible = false;
        })
        .catch((error) => {
          this.$message.error(error);

          console.error('Error uploading file:', error);

        }).finally(()=>{
          this.selectedFile = null;
          this.fileName = null;
          this.$refs.file.value = ''
        })
    },

    importfile() {
      this.centerDialogVisible = true;
    },
    changeOptions(index) {
      if (this.optionsActive == index) return
      this.optionsActive = index;
      if (this.optionsActive + 1 > this.answerOPtions.length) {
        for (let i = this.answerOPtions.length; i < this.optionsActive + 1; i++) {
          let newItem = { optionChoise: this.toEnglish[i], optionMessage: "" }
          this.answerOPtions.splice(this.answerOPtions.length, 0, newItem);
        }
      } else {
        this.answerOPtions = this.answerOPtions.slice(0, this.optionsActive + 1);
      }
    },
    goBack() {
      this.editFlag ? this.$router.push('/admin/import-records') : this.$router.push('/admin/database-manage');
    },
  },
};
</script>

<style scoped lang="scss">
.import-questions {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  height: calc(100% - 20px);

  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    position: relative;
    margin-left: 100px;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }

    .back {
      position: absolute;
      left: -80px;
      display: flex;
      align-items: center;
    }

    .reImport {
      position: absolute;
      right: 0;
    }
  }

  .question-wrap {
    margin: 20px 40px;
    width: calc(100% - 80px);



    .item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      width: 100%;

      .title {
        display: inline-block;
        width: 80px;
        font-weight: 600;
        color: #666666;
        font-size: 16px;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
      }

      .question-type {
        span {
          // width: 100px;
          border: 1px solid #d8d8d8;
          border-radius: 5px;
          text-align: center;
          padding: 5px 10px;
          margin-right: 20px;
          cursor: pointer;

          &:hover {
            border-color: #3bafda;
          }
        }

        .active {
          background: #3bafda;
          border-color: #3bafda;
          color: #fff;
        }
      }
    }

    .item1 {
      // flex-direction: column;
      align-items: flex-start;

      div {
        width: 100%;
      }
    }

    .item2 {
      // flex-direction: column;
      align-items: flex-start;

      .title {
        margin-bottom: 5px;
      }

      div {
        width: 100%;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        padding: 10px;

        span {
          display: inline-block;
          padding: 5px;
          border-radius: 5px;
          padding: 5px 10px;
          margin-right: 20px;
          cursor: pointer;
          border: 1px solid #d8d8d8;
          color: #666666;

          &:hover {
            border-color: #3bafda;
          }
        }

        .active {
          background: #3bafda;
          border-color: #3bafda;
          color: #fff;
        }
      }
    }

    .item3 {
      // flex-direction: column;
      align-items: flex-start;

      .title {
        margin-bottom: 5px;
      }

      .answer {
        width: 100%;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        padding: 10px;
        min-height: 80px;

        p {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.btn-padding {
  padding: 12px 20px;
}

.import-student-dialog {
  width: 100%;
  height: 320px;

  .content {
    overflow: hidden;
    margin-bottom: 20px;

    .step {
      height: 22px;
      box-sizing: border-box;
      border-bottom: 1px solid #3bafda;

      span {
        display: inline-block;
        width: 80px;
        height: 22px;
        color: #fff;
        background: url('../../../assets/images/step.png') no-repeat center;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .text {
      margin: 10px 0 20px 0;
    }

    .operate {
      text-align: center;
      display: flex;
      justify-content: center;

      .btn {
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 30%;

        .file {
          position: absolute;
          height: 40px;
          z-index: 1;
          opacity: 0;
        }
      }
    }
  }
}
</style>